import React from "react"
import styled from "styled-components"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"

import BlankCanvas from "../assets/BlankCanvas.svg"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import { H1 } from "../components/atoms/ResTypography"
import { PageProps } from "../helpers/props"

const SectionHero = styled.section`
  h1.title {
    margin-bottom: 1.25rem;
  }

  .il {
    height: 380px;
  }

  p {
    margin-top: 1rem;
    font-size: 1.5rem;
  }
`

const NotFoundPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const title = "404 Not Found"

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar isFixedTop={false} />
      <SectionHero className="section" id="hero">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <H1 className="title" style={{ color: "#66ce92" }}>
                {title}
              </H1>
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-centered">
              <img alt="" className="il" src={BlankCanvas} />
              <p>
                <FormattedHTMLMessage id="h_not_found" />
              </p>
            </div>
          </div>
        </div>
      </SectionHero>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
